<template>
    <div class="onboardingSteps-comp">
        <div v-show="step == 1" class="step">
            <div class="content mb16">
                <h2 class="step-title mb8">1º qual sua pergunta?</h2>
                <p>Escreva sua pergunta no centro da página. Escolha o tipo no menu suspenso.</p>
            </div>
            <img class="step-image" src="https://i.imgur.com/tYdjEO6.gif" alt="">
            <Btn @click.native="next" class="mt16" size="lg">Próximo (1/3) →</Btn>
        </div>
        <div v-show="step == 2" class="step">
            <div class="content mb16">
                <h2 class="step-title mb8">2º Adicione mais perguntas</h2>
                <p>Clique em adicionar campo na esquerda. Você pode reordenar arrastando um bloco.</p>
            </div>
            <img class="step-image" src="https://i.imgur.com/3PBDM1H.gif" alt="">
            <Btn @click.native="next" class="mt16" size="lg">Próximo (2/3) →</Btn>
        </div>
        <div v-show="step == 3" class="step">
            <div class="content mb16">
                <h2 class="step-title mb8">3º Veja uma prévia ou publique o formulário</h2>
                <p>Veja como seu formulário vai ficar. Se preferir, publique e compartilhe o link.</p>
            </div>
            <img class="step-image" src="https://i.imgur.com/htYRHxk.png" alt="">
            <Btn @click.native="end" class="mt16" size="lg">Iniciar meu formulário</Btn>
        </div>
    </div>
</template>

<script>
import Btn from './Btn.vue'
    export default {
    components: { Btn },        
        data() {
            return {
                step: 1
            }
        },

    methods: {
        next() {this.step++},
        end(){ this.$emit("end-onboarding")}
    },
        
    }
</script>

<style lang="sass" scoped>
.step
    .step-title
        font-size: $fzSm

    .step-image
        max-width: 100%
        width: 700px
        border-radius: 5px
        box-shadow: 0 0 20px rgba(0,0,0,.10)

</style>