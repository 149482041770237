<template>
  <div class="page-container page-editor-new">
      
      <TopHeader >
        <Btn @click.native="$router.back()" type="secondary" size="sm">Cancelar</Btn>
      </TopHeader>

      <main class="page-content">  


        <!-- Create form -->
        <div class="create-form fade" data-item="create-form">
            <p class="title-lg">Qual o título do formulário?</p>
            <p class="descriptio text-sm mt8">Você pode alterar mais tarde.</p>
            <TextField v-model="form.name" :data="newForm" layout="line" :error="error.name" class="mb16 mt16" />   
            <Btn @click.native="setFormName" size="lg">Próximo →</Btn>
        </div>      

        <!-- Onboarding -->
        <div class="onboarding fade hide" data-item="onboarding">
            <div class="onboarding-description mb32">
                <h2 class="title mb8">Como criar seu formulário</h2>
            </div>
            <div class="onboarding-steps">
                <OnboardingSteps @end-onboarding="createForm(form.name)" />
            </div>
            <!-- <Btn @click.native="createForm(form.name)" size="lg">Começar meu formulário →</Btn> -->
            
        </div>

      </main>
  </div>
</template>

<script>
import OnboardingSteps from '../components/form/OnboardingSteps.vue'
import Btn from '@/components/form/Btn.vue';
import TextField from '@/components/form/TextField.vue';
import {FormMixin} from '@/mixins/FormMixin.js';
import Icon from '@/components/Icon.vue';
import TopHeader from '@/components/TopHeader.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

const axios = require('axios').default;

export default {
  name: 'EditorNew',
  mixins: [FormMixin, UtilitiesMixin],
  components: {
    Btn,
    TextField,
    TopHeader,
    Icon, OnboardingSteps
  },

  data: function () {
    return {
        newForm: {
            placeholder: 'Digite um título',
            slug: 'new-form'
        },
        form: {
            name: null
        },
        teste: "Fefo",
        error: {
            name: null
        }

    }
  },


  methods: {

      setFormName(){
          if(this.form.name == null) this.error.name = "Escolha um nome para seu formulário.";
          else if(this.form.name.length < 2) this.error.name = "O nome precisa ter ao menos 2 letras.";
          else this.hideAndShowByName('create-form', 'onboarding');
      }


  },

}

</script>

<style lang="sass">

.page-editor-new
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden

    .page-content
        position: relative
        height: 100%
        display: flex
        align-items: center
        justify-content: center

    .create-form
        position: absolute
        width: 100%
        max-width: 600px
        padding: $mgSm

    .onboarding
        text-align: center
        position: absolute
        padding: $mgSm
        display: flex;
        flex-direction: column
        justify-content: center
        align-items: center

        .onboarding-description
            max-width: 500px
            line-height: 120%

        .onboarding-steps
            max-width: 90%
            max-height: 70%

                
    



</style>
